$(document).ready(function () {
    $('.match').matchHeight();
    $('.match2').matchHeight();
    stickyNav();
    jumper();
    leaving();
    scrollTo();
});

let stickyNav = () => {
    if($('.anchorNav').length > 0) {
        $(window).scroll(function () {
            if ($(this).scrollTop() > $('.anchorNav').offset().top) {
                $('.jumpLinks').show();
                $('.mtNav').addClass('sticky').addClass('slideInDown');
                $('.hideCta').hide();
            } else {
                $('.jumpLinks').hide();
                $('.mtNav').removeClass('sticky');
                $('.hideCta').show();
            }
        });
    } else {

    }
}

let scrollTo = () => {
    $('a[href*="#"]').on('click', function (e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top - 100
        }, 500, 'linear');
    });
}

let jumper = () => {
    $('.jumper').on('change', function () {
        var target = $(this).val();
        let bar = $('.mtNav').height();
        if(bar < 100){
            bar = 160;
        }
        $('html, body').animate({
            scrollTop: $(target).offset().top - bar,
        }, 1);
    });
}

const modalMaker = (template) => {
    let screen = '<div class="mtScreen"></div>';
    $('body').append(screen);
    $('body').append(template);
}
const modalRemover = () => {
    $('.mtModal').remove();
    $('.mtScreen').remove();
}

const leaving = () => {
    $('a').click(function (e) {
        if ($(this).data('modal')) {
            e.preventDefault();
            let dest = $(this).data('modal');
            let template = '<div class="mtModal"><div class="modal-content"><div class="grid-container"><div class="grid-x grid-margin-x grid-padding-y" style=""><div class="cell auto text-right" style="padding-bottom:0;"></div></div><div class="grid-x grid-margin-x grid-padding-y" style=""><div class="cell small-12 large-auto" style="padding-top:0;"><p class="lg" style="font-size:30px;line-height:34px;font-weight:bold;">You are now leaving a site managed by United Therapeutics.</p> <p>Click continue to proceed.</p><a href="' + dest + '" class="btn" target="_blank" style="">Continue</a><div class="space" style="width: 30px;display:inline-block;"></div><a href="#" class="mtClose" style="">Cancel</a></div></div></div></div></div>';
            modalMaker(template);
            $('.mtClose').click(function (e) {
                e.preventDefault();
                modalRemover();
            });
            $('.mtScreen').click(function (e) {
                e.preventDefault();
                modalRemover();
            });
        }
    });
}